import {inject, Injectable} from "@angular/core";
import {firstValueFrom, Observable} from "rxjs";
import {Event} from "@app/interfaces";
import {ApiService} from "@services/api.service";
import {environment} from "@env/environment";
import {PickedFile} from "@capawesome/capacitor-file-picker";
import {TimelineEntry} from "@interfaces/timeline-entry";
import {EventsRepository} from "@app/repositories/events.repository";
import {SettingsRepository} from "@app/repositories";


@Injectable({
  providedIn: 'root'
})
export class EventsService extends ApiService {

  private eventsRepository: EventsRepository = inject(EventsRepository);
  private settingsRepository: SettingsRepository = inject(SettingsRepository);

  fetch(): Observable<Event[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/events`);
  }

  private cacheEventsCollection(events: Event[]) {
    console.log(`${this.constructor.name}.cacheEvents`);
    this.settingsRepository.setEventsUpdatedAt();
    this.eventsRepository.updateEvents(events);
  }

  private async cacheEventItem(entry: Event) {
    console.log(`${this.constructor.name}.cacheNewsItem`, entry);
    this.eventsRepository.addEvent(entry);
  }

  async getOneFromApi(id: string): Promise<Event> {
    console.log(`${this.constructor.name}.getOneFromApi`, id);
    const event = await firstValueFrom(this.get(`/events/${id}`));

    if (event) {
      this.eventsRepository.addEvent(event);
    }

    return event;
  }

  async getOneFromApiBySlug(placeSlug: string, eventSlug: string) {
    console.log(`${this.constructor.name}.getOneFromApiBySlug`, eventSlug);
    const event = await firstValueFrom(this.get(`/events/${eventSlug}`));

    if (event) {
      await this.cacheEventItem(event);
    }

    return event;
  }

  public async getAllFromApi(): Promise<Event[]> {
    return firstValueFrom(this.fetch()).then((fetchedEvents: Event[]) => {
      this.cacheEventsCollection(fetchedEvents);
      console.log(`${this.constructor.name}.getAllFromApi`, fetchedEvents);

      return fetchedEvents;
    });
  }

  async clearCache() {
    console.log(`${this.constructor.name}.clearCache`);
    this.settingsRepository.removeEventsUpdatedAt();
    this.eventsRepository.updateEvents([]);
  }

  create(event: Event): Observable<Event> {
    console.log(`${this.constructor.name}.create`, event);
    return this.post(`/events`, event);
  }

  update(event: Event): Observable<Event> {
    console.log(`${this.constructor.name}.update`, event);
    return this.put(`/events/${event.id}`, event);
  }

  uploadVideo(event: Event, video: PickedFile): Observable<any> {
    console.log(`${this.constructor.name}.uploadVideo`, event.id, video);

    if (video.blob === undefined) {
      throw new Error('No video blob found');
    }

    const formData = new FormData();
    formData.append('video', video.blob, video.name);

    const url = `${environment.apiBaseUrl}/events/${event.id}/videos`;

    return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
  }

  async toggleLike(entry: TimelineEntry): Promise<Event> {
    console.log(`${this.constructor.name}.toggleLike`);
    const news = await firstValueFrom(this.post(`/events/${entry.id}/like`, {}));

    await this.cacheEventItem(news);

    return news;
  }
}
